<template>
  <div class="navdesktop" :class="{ isblack: store.state.isMenuOpen }">
    <div class="navdesktop__wrapper">
      <div class="navdesktop__left">
        <div class="navdesktop__logo">
          <!-- <TheLogo :class="{store.state.isMenuOpen ? isblack : ''}" /> -->
          <TheLogo :class="{ isblack: store.state.isMenuOpen }" />
        </div>
      </div>
      <div class="navdesktop__middle">
        <NavMenuBtn :class="{ isblack: store.state.isMenuOpen }" />
      </div>
      <div class="navdesktop__right">
        <div
          class="navdesktop__messagetext"
          :class="{ isblack: store.state.isMenuOpen }"
        >
          {{ messageText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavBtn from "./NavBtn";
import TheLogo from "@/components/ui/logo/TheLogo.vue";
import { computed } from "@vue/runtime-core";
import NavMenuBtn from "./NavMenuBtn";

import { useStore } from "vuex";
export default {
  components: { TheLogo, NavMenuBtn },

  setup() {
    const store = useStore();
    const publicPath = process.env.BASE_URL;

    const isPeople = computed(() => {
      return store.state.isPeopleOpen;
    });
    const isHelp = computed(() => {
      return store.state.isHelpDeskOpen;
    });
    const isMenuOpen = computed(() => {
      return store.state.isMenuOpen;
    });

    const messageText = computed(() => {
      if (store.state.isMenuOpen) {
        return "menü";
      } else {
        return store.state.isActiveRouteName;
      }
    });
    //  const getPath = function(path) {
    // let newP = path.split(".jpg");
    // if (newP.length >= 2) {
    //   return publicPath + path;
    // }}

    function prefixPath(el) {
      return publicPath + el;
    }

    function toggleElement(e, el) {
      console.log("e", e, el);
    }
    return {
      isPeople,
      isHelp,
      isMenuOpen,
      toggleElement,
      prefixPath,
      store,
      messageText,
    };
  },
};
</script>
